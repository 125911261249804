@font-face {
  font-family: "Gilroy-regular";
  src: url("../fonts/Gilroy-Regular.woff") format("woff"),
    url("../fonts/Gilroy-Regular.woff2") format("woff2"),
    url("../fonts/Gilroy-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Gilroy-bold";
  src: url("../fonts/Gilroy-Bold.woff") format("woff"),
    url("../fonts/Gilroy-Bold.woff2") format("woff2"),
    url("../fonts/Gilroy-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Gilroy-semibold";
  src: url("../fonts/gilroy-semibold.woff") format("woff"),
    url("../fonts/gilroy-semibold.woff2") format("woff2"),
    url("../fonts/gilroy-semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "New-YorkerC";
  src: url("../fonts/New-YorkerC.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}