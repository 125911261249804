html
  height 100%
.hide
  display none

.hidden
  overflow hidden

.clearfix
  zoom 1

.clearfix:after
  clear both
  content ''
  display block
  height 0
  visibility hidden

.nobr
  white-space nowrap

.italic
  font-style italic

.float-left
  float left

.float-right
  float right

.align-left
  text-align left

.align-right
  text-align right

.align-center
  text-align center

a
  text-decoration none
  color inherit

p
  margin 0
  
img
  display block
  max-width 100%
    
strong
  font-weight 600