$columns = 12
$atom = (100% / $columns)

$break_xxl = 1400px
$break_xl = 1200px
$break_lg = 960px
$break_md = 768px
$break_sm = 768px
$break_xs = 480px

$offset = 20px
$offset_md = $offset
$offset_sm = $offset
$offset_xs = $offset

$offset_one_side = ($offset / 2)
$offset_md_one_side = ($offset_md / 2)
$offset_sm_one_side = ($offset_sm / 2)
$offset_xs_one_side = ($offset_xs / 2)

$fields = 20px
$fields_sm = 15px

wrapper-full()
    padding-left $fields
    padding-right $fields

    +sm-block()
        padding-left $fields_sm
        padding-right $fields_sm

row-offsets()
    margin-left ($offset_one_side * -1)
    margin-right ($offset_one_side * -1)

col-offsets($type)
    {$type}-left $offset_one_side
    {$type}-right $offset_one_side

wrapper()
    max-width 1600px
    margin 0 auto
    wrapper-full()

row-flex()
    display flex
    flex-wrap wrap
    row-offsets()

row-float()
    row-offsets()
    clearfix()

row-ib()
    row-offsets()

col()
    box-sizing border-box
    word-wrap break-word
    col-offsets(margin)

col-float()
    float left

col-ib()
    display inline-block
    vertical-align top

col-padding()
    word-wrap break-word
    col-offsets(padding)

size($n)
    $val = 100% / $columns * $n
    width "calc(%s - %s)" % ($val $offset)

size-md($n)
    $val = 100% / $columns * $n
    md(width, "calc(%s - %s)" % ($val $offset_md))

size-sm($n)
    $val = 100% / $columns * $n
    sm(width, "calc(%s - %s)" % ($val $offset_sm))

size-xs($n)
    $val = 100% / $columns * $n
    xs(width, "calc(%s - %s)" % ($val $offset_xs))

shift($n)
    $val = 100% / $columns * $n
    margin-left "calc(%s + %s)" % ($val $offset_one_side)
    margin-right "calc(%s + %s)" % ($val $offset_one_side)

shift-left($n)
    $val = 100% / $columns * $n
    margin-left "calc(%s + %s)" % ($val $offset_one_side)

shift-right($n)
    $val = 100% / $columns * $n
    margin-right "calc(%s + %s)" % ($val $offset_one_side)

shift-padding($n)
    $val = 100% / $columns * $n
    padding-left "calc(%s + %s)" % ($val $offset_one_side)
    padding-right "calc(%s + %s)" % ($val $offset_one_side)

shift-padding-left($n)
    $val = 100% / $columns * $n
    padding-left "calc(%s + %s)" % ($val $offset_one_side)

shift-padding-right($n)
    $val = 100% / $columns * $n
    padding-right "calc(%s + %s)" % ($val $offset_one_side)

shift-md($n)
    $val = 100% / $columns * $n
    md(margin-left, "calc(%s + %s)" % ($val $offset_md_one_side))
    md(margin-right, "calc(%s + %s)" % ($val $offset_md_one_side))

shift-md-left($n)
    $val = 100% / $columns * $n
    md(margin-left, "calc(%s + %s)" % ($val $offset_md_one_side))

shift-md-right($n)
    $val = 100% / $columns * $n
    md(margin-right, "calc(%s + %s)" % ($val $offset_md_one_side))

shift-md-padding($n)
    $val = 100% / $columns * $n
    md(padding-left, "calc(%s + %s)" % ($val $offset_md_one_side))
    md(padding-right, "calc(%s + %s)" % ($val $offset_md_one_side))

shift-md-padding-left($n)
    $val = 100% / $columns * $n
    md(padding-left, "calc(%s + %s)" % ($val $offset_md_one_side))

shift-md-padding-right($n)
    $val = 100% / $columns * $n
    md(padding-right, "calc(%s + %s)" % ($val $offset_md_one_side))

shift-sm($n)
    $val = 100% / $columns * $n
    sm(margin-left, "calc(%s + %s)" % ($val $offset_sm_one_side))
    sm(margin-right, "calc(%s + %s)" % ($val $offset_sm_one_side))

shift-sm-left($n)
    $val = 100% / $columns * $n
    sm(margin-left, "calc(%s + %s)" % ($val $offset_sm_one_side))

shift-sm-right($n)
    $val = 100% / $columns * $n
    sm(margin-right, "calc(%s + %s)" % ($val $offset_sm_one_side))

shift-sm-padding($n)
    $val = 100% / $columns * $n
    sm(padding-left, "calc(%s + %s)" % ($val $offset_sm_one_side))
    sm(padding-right, "calc(%s + %s)" % ($val $offset_sm_one_side))

shift-sm-padding-left($n)
    $val = 100% / $columns * $n
    sm(padding-left, "calc(%s + %s)" % ($val $offset_sm_one_side))

shift-sm-padding-right($n)
    $val = 100% / $columns * $n
    sm(padding-right, "calc(%s + %s)" % ($val $offset_sm_one_side))

shift-xs($n)
    $val = 100% / $columns * $n
    xs(margin-left, "calc(%s + %s)" % ($val $offset_xs_one_side))
    xs(margin-right, "calc(%s + %s)" % ($val $offset_xs_one_side))

shift-xs-left($n)
    $val = 100% / $columns * $n
    xs(margin-left, "calc(%s + %s)" % ($val $offset_xs_one_side))

shift-xs-right($n)
    $val = 100% / $columns * $n
    xs(margin-right, "calc(%s + %s)" % ($val $offset_xs_one_side))

shift-xs-padding($n)
    $val = 100% / $columns * $n
    xs(padding-left, "calc(%s + %s)" % ($val $offset_xs_one_side))
    xs(padding-right, "calc(%s + %s)" % ($val $offset_xs_one_side))

shift-xs-padding-left($n)
    $val = 100% / $columns * $n
    xs(padding-left, "calc(%s + %s)" % ($val $offset_xs_one_side))

shift-xs-padding-right($n)
    $val = 100% / $columns * $n
    xs(padding-right, "calc(%s + %s)" % ($val $offset_xs_one_side))

md($name, $value)
    @media screen and (max-width: $break_md)
        {$name} $value

md-block()
    @media screen and (max-width: $break_md)
        {block}

sm($name, $value)
    @media screen and (max-width: $break_sm)
        {$name} $value

sm-block()
    @media screen and (max-width: $break_sm)
        {block}

xs($name, $value)
    @media screen and (max-width: $break_xs)
        {$name} $value

xs-block()
    @media screen and (max-width: $break_xs)
        {block}

from($min_width)
    @media screen and (min-width: $min_width)
        {block}

to($max_width)
    @media screen and (max-width: $max_width)
        {block}

from-to($min_width, $max_width)
    @media screen and (min-width: $min_width) and (max-width: $max_width)
        {block}

u-row-flex($offset)
    display flex
    flex-wrap wrap
    margin-left ($offset / -2)
    margin-right ($offset / -2)

u-col($offset)
    box-sizing border-box
    word-wrap break-word
    margin-left ($offset / 2)
    margin-right ($offset / 2)

u-size($n, $columns, $offset)
    width "calc(100% / %s * %s - %s)" % ($columns $n $offset)

reset()
    body,div,dl,dt,dd,ul,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,input,textarea,p,blockquote,th,td
        margin 0
        padding 0
    
    table
        border-collapse collapse
        border-spacing 0
    
    fieldset,img,abbr
        border 0
    
    address,caption,cite,code,dfn,em,strong,th,var
        font-style normal
        font-weight normal
    
    ul li
        list-style none
    
    caption,th
        text-align left
    
    h1,h2,h3,h4,h5,h6
        font-size 100%
        font-weight normal
    
    sup
        vertical-align text-top
    
    sub
        vertical-align text-bottom
    
    input,textarea,select
        font-family inherit
        font-size inherit
        font-weight inherit
    
    legend
        color #000
    
    article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main
        display block
    
    img
        max-width 100%
        height auto

debug($background, $outline)
    height 100vh
    position fixed
    width 100%
    
    > div
        height inherit
        wrapper()
        
        > div
            height inherit
            row-flex()
            
            > div
                col()
                size(1)
                height inherit
                outline $outline
                background $background

clearfix()
    &::after
        display block
        content ""
        clear both
    
