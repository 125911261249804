@font-face {
  font-family: "Gilroy-regular";
  src: url("../fonts/Gilroy-Regular.woff") format("woff"), url("../fonts/Gilroy-Regular.woff2") format("woff2"), url("../fonts/Gilroy-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Gilroy-bold";
  src: url("../fonts/Gilroy-Bold.woff") format("woff"), url("../fonts/Gilroy-Bold.woff2") format("woff2"), url("../fonts/Gilroy-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Gilroy-semibold";
  src: url("../fonts/gilroy-semibold.woff") format("woff"), url("../fonts/gilroy-semibold.woff2") format("woff2"), url("../fonts/gilroy-semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "New-YorkerC";
  src: url("../fonts/New-YorkerC.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
* {
  margin: 0;
  padding: 0;
  font: inherit;
  box-sizing: border-box;
  will-change: all;
}
*:before,
*:after {
  box-sizing: border-box;
  will-change: all;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-focus-ring-color: rgba(255,255,255,0);
}
body {
  font-size: 100%;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
a {
  background: transparent;
}
*:focus,
*:active,
*:hover {
  outline: 0;
}
hr {
  box-sizing: content-box;
  height: 0;
}
ol,
ul {
  list-style: none;
}
pre {
  tab-size: 4;
  white-space: pre-wrap;
}
q {
  quotes: '\201C' '\201D' '\2018' '\2019';
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input {
  line-height: normal;
}
button,
select {
  text-transform: none;
}
button {
  overflow: visible;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"],
input[type="email"],
input[type="password"],
input[type="text"],
input[type="phone"] {
  -webkit-appearance: none;
  box-sizing: border-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
button,
input,
select[multiple],
textarea {
  background-image: none;
}
input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}
input,
textarea {
  resize: none;
  user-select: text;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  height: 100%;
}
.hide {
  display: none;
}
.hidden {
  overflow: hidden;
}
.clearfix {
  zoom: 1;
}
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
  visibility: hidden;
}
.nobr {
  white-space: nowrap;
}
.italic {
  font-style: italic;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
a {
  text-decoration: none;
  color: inherit;
}
p {
  margin: 0;
}
img {
  display: block;
  max-width: 100%;
}
strong {
  font-weight: 600;
}
body {
  background-color: #f8f6ee;
  overflow-x: hidden;
}
@media screen and (max-width: 768px) {
  html {
    overflow-x: hidden;
  }
}
.mainScreen {
  font-size: 10px;
}
@media screen and (max-width: 768px) {
  .mainScreen {
    width: 100%;
  }
}
.mainScreen__row {
  position: relative;
  display: flex;
}
@media screen and (max-width: 768px) {
  .mainScreen__row {
    display: block;
  }
}
.mainScreen__row:after {
  display: block;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #365166;
  bottom: 0;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .mainScreen__row:after {
    display: none;
  }
}
.mainScreen__bigTitle {
  display: flex;
  align-items: center;
  position: relative;
  font-size: calc(54px + (100 - 54) * (100vw - 400px) / (1920 - 400));
  font-family: "New-YorkerC";
  text-transform: uppercase;
  color: #365166;
}
@media screen and (max-width: 768px) {
  .mainScreen__bigTitle {
    display: block;
  }
}
.mainScreen__headerWhats {
  float: right;
  margin-top: 1em;
  display: flex;
  margin-right: 50px;
}
@media screen and (max-width: 768px) {
  .mainScreen__headerWhats {
    float: left;
  }
}
@media screen and (max-width: 768px) {
  .mainScreen__headerWhats {
    margin-right: 10px;
  }
}
@media screen and (max-width: 768px) {
  .mainScreen__headerWhats {
    margin-left: 10px;
  }
}
@media screen and (max-width: 768px) {
  .mainScreen__headerWhats {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .mainScreen__headerWhats {
    align-items: center;
  }
}
.mainScreen__headerWhats img {
  margin-right: 2em;
}
@media screen and (max-width: 768px) {
  .mainScreen__headerWhats img {
    margin-right: 10px;
  }
}
.mainScreen__headerWhats a {
  font-size: 20px;
  font-family: "Gilroy-regular";
  color: #365166;
}
.mainScreen__buttonPopup {
  display: flex;
  margin-top: 60px;
}
.mainScreen__buttonPopup img {
  margin-left: 2.5em;
}
.mainScreen__buttonPopup a {
  font-size: calc(20px + (30 - 20) * (100vw - 400px) / (1920 - 400));
  text-transform: uppercase;
  font-family: "Gilroy-regular";
  color: #365166;
}
.mainScreen__blockTitleBtn {
  min-width: 500px;
  position: relative;
  margin-top: 13.7em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 340px;
  margin-left: 8em;
}
@media screen and (max-width: 768px) {
  .mainScreen__blockTitleBtn {
    margin-left: 10px;
  }
}
@media screen and (max-width: 768px) {
  .mainScreen__blockTitleBtn {
    margin-top: 0em;
  }
}
@media screen and (max-width: 768px) {
  .mainScreen__blockTitleBtn {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .mainScreen__blockTitleBtn {
    min-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .mainScreen__col {
    display: none;
  }
}
.mainScreen__wrapImg {
  position: absolute;
  left: 57%;
}
@media screen and (max-width: 768px) {
  .mainScreen__wrapImg {
    display: none;
  }
}
.belowMain {
  margin-top: 8em;
}
@media screen and (max-width: 768px) {
  .belowMain {
    margin-top: 3em;
  }
}
.belowMain__block {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .belowMain__block {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .belowMain__block img {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .belowMain__block img {
    margin-left: -10px;
  }
}
.belowMain div {
  margin-bottom: 10%;
  font-size: calc(20px + (30 - 20) * (100vw - 400px) / (1920 - 400));
  color: #365166;
  text-transform: uppercase;
  font-family: "New-YorkerC";
}
.belowMain img {
  margin-right: 35px;
}
@media screen and (max-width: 768px) {
  .belowMain img {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .belowMain img {
    margin-right: 10px;
  }
}
.titleBlock {
  font-size: calc(30px + (50 - 30) * (100vw - 400px) / (1920 - 400));
  color: #365166;
  font-family: "New-YorkerC";
}
.blockDesck {
  margin-top: 18em;
  padding-left: 50px;
  padding-right: 50px;
}
@media screen and (max-width: 768px) {
  .blockDesck {
    margin-top: 500px;
  }
}
@media screen and (max-width: 768px) {
  .blockDesck {
    padding-right: 0px;
  }
}
@media screen and (max-width: 768px) {
  .blockDesck {
    padding-left: 10px;
  }
}
.blockDesck__title {
  margin-bottom: 1.5em;
}
.blockDesck__row {
  margin-top: 50px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .blockDesck__row {
    display: block;
  }
}
.blockDesck__col-left {
  width: 55%;
  padding-right: 50px;
}
@media screen and (max-width: 768px) {
  .blockDesck__col-left {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .blockDesck__col-left {
    padding-right: 0px;
  }
}
.blockDesck__col-left p {
  margin-top: 0.8em;
  font-size: 22px;
  font-family: "Gilroy-regular";
  color: #365166;
}
.blockDesck__col {
  display: block;
  width: 45%;
  position: relative;
  background-position: left;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .blockDesck__col {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .blockDesck__col {
    margin-top: 20px;
  }
}
.blockDesck__col:before {
  display: block;
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-80%, -50%);
  content: url("../images/back2.png");
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .blockDesck__col:before {
    display: none;
  }
}
.blockAdvantages {
  padding-left: 50px;
  width: 100%;
  position: relative;
  background-color: #4a4a4a;
  height: 55em;
}
@media screen and (max-width: 768px) {
  .blockAdvantages {
    padding-left: 10px;
  }
}
@media screen and (max-width: 768px) {
  .blockAdvantages {
    height: 70em;
  }
}
.blockAdvantages__title {
  padding-top: 96px;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .blockAdvantages__title {
    padding-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .blockAdvantages__title {
    line-height: 35px;
  }
}
.blockAdvantages__col {
  width: 59%;
}
@media screen and (max-width: 768px) {
  .blockAdvantages__col {
    width: 100%;
  }
}
.blockAdvantages__col p {
  color: #fff;
  font-size: calc(18px + (22 - 18) * (100vw - 400px) / (1920 - 400));
  font-family: "Gilroy-regular";
}
.blockAdvantages__row p {
  margin-top: 8em;
  width: 50%;
  color: #fff;
  font-size: calc(18px + (22 - 18) * (100vw - 400px) / (1920 - 400));
  font-family: "Gilroy-regular";
}
@media screen and (max-width: 768px) {
  .blockAdvantages__row p {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .blockAdvantages__row p {
    margin-top: 2em;
  }
}
.blockAdvantages__row-img {
  margin-top: 5em;
  display: flex;
}
@media screen and (max-width: 768px) {
  .blockAdvantages__row-img {
    display: block;
  }
}
.blockAdvantages__row-img p {
  margin-left: 18em;
  width: 49%;
}
@media screen and (max-width: 768px) {
  .blockAdvantages__row-img p {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .blockAdvantages__row-img p {
    margin-left: 0em;
  }
}
.blockAdvantages__row-left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.blockAdvantages:after {
  top: 0;
  left: -8%;
  position: absolute;
  content: url("../images/back3.png");
}
@media screen and (max-width: 768px) {
  .blockAdvantages:after {
    display: none;
  }
}
.blockAdvantages:before {
  top: 210px;
  right: 2em;
  position: absolute;
  content: url("../images/img4.png");
}
@media screen and (max-width: 768px) {
  .blockAdvantages:before {
    display: none;
  }
}
.advantagesList {
  padding-top: 9em;
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 768px) {
  .advantagesList {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .advantagesList {
    padding-top: 4em;
  }
}
.advantagesList__item {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .advantagesList__item {
    margin-bottom: 20px;
  }
}
.advantagesList__bignum {
  font-size: calc(54px + (100 - 54) * (100vw - 400px) / (1920 - 400));
  color: #365166;
  font-family: "New-YorkerC";
}
.advantagesList__text {
  font-size: calc(18px + (22 - 18) * (100vw - 400px) / (1920 - 400));
  font-family: "Gilroy-regular";
  color: #595959;
}
.blockLocation {
  padding-right: 50px;
  margin-top: 140px;
}
@media screen and (max-width: 768px) {
  .blockLocation {
    padding-right: 10px;
  }
}
@media screen and (max-width: 768px) {
  .blockLocation {
    margin-top: 50px;
  }
}
.blockLocation__row {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media screen and (max-width: 768px) {
  .blockLocation__row {
    display: block;
  }
}
.blockLocation__title {
  margin-bottom: 1em;
}
.blockLocation__col-left {
  width: 58%;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .blockLocation__col-left {
    width: 100%;
  }
}
.blockLocation__text {
  font-size: calc(18px + (22 - 18) * (100vw - 400px) / (1920 - 400));
  font-family: "Gilroy-regular";
  color: #595959;
  width: 430px;
}
@media screen and (max-width: 768px) {
  .blockLocation__text {
    width: 100%;
  }
}
.blockLocation__wrapper {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .blockLocation__wrapper {
    display: block;
  }
}
.blockLocation__back {
  padding-top: 100px;
  padding-left: 100px;
  width: 670px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .blockLocation__back {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .blockLocation__back {
    padding-left: 0px;
  }
}
@media screen and (max-width: 768px) {
  .blockLocation__back {
    padding-top: 20px;
  }
}
.blockLocation__back:after {
  top: 0;
  left: 0;
  position: absolute;
  content: url("../images/svg/backLocation.svg");
}
@media screen and (max-width: 768px) {
  .blockLocation__back:after {
    display: none;
  }
}
.blockLocation__popup {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .blockLocation__col {
    margin-top: 30px;
  }
}
.blockLocation__style {
  margin-top: 20px;
  font-family: "Gilroy-regular";
  font-size: calc(18px + (22 - 18) * (100vw - 400px) / (1920 - 400));
  color: #595959;
}
.blockInvesticion {
  margin-top: 120px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .blockInvesticion {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .blockInvesticion {
    margin-top: 30px;
  }
}
.blockInvesticion__row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .blockInvesticion__row {
    display: block;
  }
}
.blockInvesticion__col-left {
  position: relative;
  display: flex;
  width: 55%;
}
@media screen and (max-width: 768px) {
  .blockInvesticion__col-left {
    width: 100%;
  }
}
.blockInvesticion__col-left div {
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .blockInvesticion__col-left div {
    margin-right: 10px;
  }
}
.blockInvesticion__col {
  padding-right: 50px;
  width: 43%;
}
@media screen and (max-width: 768px) {
  .blockInvesticion__col {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .blockInvesticion__col {
    padding-right: 10px;
  }
}
@media screen and (max-width: 768px) {
  .blockInvesticion__col {
    margin-top: 30px;
  }
}
.blockInvesticion__col-left:after {
  position: absolute;
  right: -100px;
  bottom: -70px;
  display: block;
  content: url("../images/svg/backInvesticion.svg");
}
@media screen and (max-width: 768px) {
  .blockInvesticion__col-left:after {
    display: none;
  }
}
.planirovki {
  margin-top: 100px;
  margin-right: 50px;
}
@media screen and (max-width: 768px) {
  .planirovki {
    margin-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  .planirovki {
    margin-right: 10px;
  }
}
.planirovki__titleBlock {
  display: flex;
}
@media screen and (max-width: 768px) {
  .planirovki__titleBlock {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .planirovki__titleBlock img {
    display: none;
  }
}
.planirovki__row {
  position: relative;
}
@media screen and (max-width: 768px) {
  .planirovki__row {
    margin-bottom: 110%;
  }
}
.tab {
  margin-top: 77px;
}
@media screen and (max-width: 768px) {
  .tab {
    margin-top: 10px;
  }
}
.tab__row-wrapp {
  display: flex;
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .tab__row-wrapp {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .tab__row-wrapp {
    margin-bottom: 0px;
  }
}
.tab__item {
  margin-right: 45px;
  font-size: calc(18px + (22 - 18) * (100vw - 400px) / (1920 - 400));
  font-family: "Gilroy-regular";
  color: #595959;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .tab__item {
    margin-top: 10px;
  }
}
.tab__description {
  position: relative;
  margin-top: 80px;
  font-size: calc(18px + (22 - 18) * (100vw - 400px) / (1920 - 400));
  font-family: "Gilroy-semibold";
  color: #595959;
}
.tab::before {
  position: absolute;
  bottom: 140px;
  display: block;
  content: '';
  width: 50%;
  height: 1px;
  border-top: 2px dashed #b4c4d0;
  border-bottom-width: 2px;
}
@media screen and (max-width: 768px) {
  .tab::before {
    margin-top: 20px;
  }
}
.tab__button {
  margin-top: 60px;
}
.tab__img {
  position: absolute;
  right: 8%;
  bottom: 0;
}
@media screen and (max-width: 768px) {
  .tab__img {
    top: 150px;
  }
}
.tabs__content {
  display: none;
}
.tabs__content--active {
  display: block;
}
.sliderBlock {
  overflow-x: hidden;
  position: relative;
  padding: 100px 0px 0px 100px;
  margin-top: 200px;
  background-color: #4a4a4a;
  height: 700px;
}
@media screen and (max-width: 768px) {
  .sliderBlock {
    padding: 10px;
  }
}
@media screen and (max-width: 768px) {
  .sliderBlock {
    margin-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  .sliderBlock {
    margin-left: -10px;
  }
}
@media screen and (max-width: 768px) {
  .sliderBlock {
    height: 100%;
  }
}
.sliderBlock__row {
  display: flex;
}
@media screen and (max-width: 768px) {
  .sliderBlock__row {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .sliderBlock__row {
    margin-bottom: 20px;
  }
}
.sliderBlock__title {
  color: #fff;
}
.sliderBlock__style {
  margin-top: 30px;
  font-family: "Gilroy-regular";
  color: #fff;
  font-size: calc(18px + (22 - 18) * (100vw - 400px) / (1920 - 400));
}
.sliderBlock__col {
  position: relative;
  width: 35%;
}
@media screen and (max-width: 768px) {
  .sliderBlock__col {
    width: 100%;
  }
}
.sliderBlock__col-left {
  width: 80%;
  margin-right: 20px;
}
@media screen and (max-width: 768px) {
  .sliderBlock__col-left {
    width: 100%;
  }
}
.slick-list {
  padding: 0 20% 0 0;
}
@media screen and (max-width: 768px) {
  .slick-list {
    padding: 0;
  }
}
.sliderBlock:after {
  position: absolute;
  display: block;
  bottom: -5%;
  left: 0%;
  content: url("../images/img-backSlider.png");
}
@media screen and (max-width: 768px) {
  .sliderBlock:after {
    display: none;
  }
}
.slider__content {
  width: 700px;
  margin-top: 3%;
}
@media screen and (max-width: 768px) {
  .slider__content {
    width: 100%;
  }
}
.slider__content img {
  width: 100%;
}
.slick-slide {
  margin: 0 10px;
}
@media screen and (max-width: 768px) {
  .slick-slide {
    margin: 0;
  }
}
.slick-list {
  margin: 0 -10px;
}
@media screen and (max-width: 768px) {
  .slick-list {
    margin: 0;
  }
}
.arrow {
  display: flex;
  margin-bottom: 40px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .arrow {
    padding: 10px;
  }
}
.arrow__next {
  margin-left: 20px;
}
.features {
  margin-top: 110px;
}
@media screen and (max-width: 768px) {
  .features {
    margin-top: 120px;
  }
}
.features__title {
  text-align: center;
  margin-bottom: 120px;
}
@media screen and (max-width: 768px) {
  .features__title {
    margin-bottom: 20px;
  }
}
.features__row {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 768px) {
  .features__row {
    display: block;
  }
}
.features__col {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .features__col {
    height: 250px;
  }
}
@media screen and (max-width: 768px) {
  .features__col {
    margin-top: 20px;
  }
}
.features__col div {
  width: 70%;
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 768px) {
  .features__col div {
    width: 100%;
  }
}
.features__col div p {
  margin-left: 10px;
  font-family: "Gilroy-regular";
  font-size: calc(18px + (22 - 18) * (100vw - 400px) / (1920 - 400));
  color: #595959;
}
.desckBlock {
  margin-top: 150px;
}
@media screen and (max-width: 768px) {
  .desckBlock {
    margin-top: 30px;
  }
}
.desckBlock__col-left {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .desckBlock__col-left {
    display: block;
  }
}
.desckBlock__col {
  width: 55%;
}
@media screen and (max-width: 768px) {
  .desckBlock__col {
    width: 100%;
  }
}
.desckBlock__title {
  margin-top: 50px;
  margin-bottom: 70px;
}
@media screen and (max-width: 768px) {
  .desckBlock__title {
    margin-top: 0px;
  }
}
@media screen and (max-width: 768px) {
  .desckBlock__title {
    margin-bottom: 30px;
  }
}
.footer {
  margin-left: -50px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .footer {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    margin-left: -10px;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    margin-top: 30px;
  }
}
.footer__col {
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 70px;
  background-color: #4a4a4a;
  color: #fff;
  width: 60%;
}
@media screen and (max-width: 768px) {
  .footer__col {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .footer__col {
    padding-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  .footer__col {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .footer__col {
    display: block;
  }
}
.footer__col-left {
  padding-bottom: 50px;
  padding-top: 70px;
  padding-left: 50px;
  width: 40%;
  margin-right: 10px;
  background-color: #4a4a4a;
}
@media screen and (max-width: 768px) {
  .footer__col-left {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .footer__col-left {
    padding-left: 10px;
  }
}
@media screen and (max-width: 768px) {
  .footer__col-left {
    padding-bottom: 10px;
  }
}
.footer__title {
  color: #fff;
}
.footer__rowWrapp {
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  font-family: "Gilroy-regular";
  font-size: calc(18px + (22 - 18) * (100vw - 400px) / (1920 - 400));
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .footer__rowWrapp {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .footer__rowWrapp {
    padding-left: 10px;
  }
}
@media screen and (max-width: 768px) {
  .footer__rowWrapp {
    margin-top: 10px;
  }
}
.footer__social {
  display: flex;
}
@media screen and (max-width: 768px) {
  .footer__social {
    margin-top: 20px;
  }
}
.footer__social a img {
  margin-left: 20px;
}
@media screen and (max-width: 768px) {
  .footer__social a img {
    margin-left: 0px;
  }
}
@media screen and (max-width: 768px) {
  .footer__social a img {
    margin-right: 20px;
  }
}
.footer__contactBlock {
  margin-top: -10%;
  padding-left: 50px;
  font-family: "Gilroy-regular";
  font-size: calc(18px + (22 - 18) * (100vw - 400px) / (1920 - 400));
}
@media screen and (max-width: 768px) {
  .footer__contactBlock {
    padding-left: 10px;
  }
}
.footer__logoCompany {
  display: flex;
}
@media screen and (max-width: 768px) {
  .footer__logoCompany {
    margin-top: 30px;
  }
}
.footer__logoCompany img {
  margin-left: 10px;
}
.footer__contact {
  margin-top: 50px;
  margin-bottom: 20px;
}
.footer__linkPolitica {
  margin-top: 70px;
}
@media screen and (max-width: 768px) {
  .footer__linkPolitica {
    margin-top: 10px;
  }
}
.footer__contactForm {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 80%;
}
@media screen and (max-width: 768px) {
  .footer__contactForm {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .footer__contactForm {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .footer__contactForm {
    margin-top: 0px;
  }
}
.footer__contactForm input {
  padding-bottom: 10px;
  margin-top: 50px;
  background-color: #4a4a4a;
  color: #fff;
  border: none;
  border-bottom: 1px solid #fff;
}
@media screen and (max-width: 768px) {
  .footer__contactForm input {
    margin-top: 30px;
  }
}
.footer__contactForm input::placeholder {
  font-family: "Gilroy-regular";
  font-size: calc(18px + (22 - 18) * (100vw - 400px) / (1920 - 400));
  color: #fff;
}
.footer__contactForm div p {
  margin-top: 52px;
  font-size: 18px;
  margin-left: 10px;
  color: #fff;
  font-family: "Gilroy-regular";
}
@media screen and (max-width: 768px) {
  .footer__contactForm div p {
    margin-top: 20px;
  }
}
.footer__contactForm div {
  display: flex;
  align-items: center;
}
.popup-fade {
  display: none;
}
.popup-fade:before {
  content: '';
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 3;
}
.popup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #4a4a4a;
  z-index: 5;
  opacity: 1;
  padding: 60px 90px;
  width: 100%;
  max-width: 770px;
}
@media screen and (max-width: 768px) {
  .popup {
    padding: 40px 10px;
  }
}
.popup__title {
  color: #fff !important;
  text-align: center;
}
.popupForm {
  text-align: center;
  margin: 0 auto;
  width: 100%;
}
.popup__inputStyle {
  width: 100%;
}
.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
}
@media (max-width: 1600px) {
  .blockAdvantages:before {
    right: 4em;
  }
}
@media (max-width: 1420px) {
  .belowMain__block {
    flex-wrap: wrap;
  }
  .belowMain div {
    margin-bottom: 20%;
  }
  .tab__item {
    margin-right: 20px;
  }
}
